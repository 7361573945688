export default {
    state : {
        bots : [],
    },
    getters : {
        bots(state) {
            return state.bots
        },

        bot(state) {
            return (id) => {
                return { ...state.bots.filter(elem => elem.id == id)[0] }
            }
        }
    },
    mutations : {
        set_bots(state, data) {
            state.bots = data
        },
        append_bot(state, new_bot) {
            state.bots.push(new_bot)
        },
    },
    actions : {
        async load_bots(ctx) {
            let response = await ctx.dispatch('api_request', {
                endpoint: '/get_bots'
            })
            console.log(response)
            ctx.commit('set_bots', response)
        },
    }
}