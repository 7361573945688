export default {
    actions: {
      async api_request({ getters, dispatch }, { endpoint, headers, body}) {
  
        let options = {
          headers: headers || {}
        }
  
        if (getters.token != undefined) {
          options.headers.token = getters.token
        }
  
        if (body) {
          options.method = 'POST'
          options.headers['Content-type'] = 'application/json'
          options.body = JSON.stringify(body)
        }
        
        let response = await fetch(getters.url+'/api/ui'+endpoint, options)
        response = await response.json()
  
        if (!response.result) {
          console.error(`Request ${endpoint} failed: (${response.error_class}) ${response.error_text}`)
  
          if (response.error_class == 'UserNotFoundException' || response.error_class == 'TokenNotFoundException') {
            await dispatch('set_token', undefined)
          }
  
          throw new Error(response.error_text)
        } else {
          return response.data

        }
      },
  
      async api_request_file({ getters }, { endpoint, headers, body, result_name }) {
        let options = {
          headers: headers || {}
        }
  
        if (getters.token != undefined) {
          options.headers.token = getters.token
        }
  
        if (body) {
          options.method = 'POST'
          options.headers['Content-type'] = 'application/json'
          options.body = JSON.stringify(body)
        }
        
        let response = await fetch(getters.url+'/api/ui'+endpoint, options)
  
        if(response.status !== 200) {
          console.error('ERROR!')
        } else {
          let obj = await response.blob()
          let downloadUrl = window.URL.createObjectURL(obj)
  
          let link = document.createElement('a');
          link.href = downloadUrl;
          link.download = result_name;
  
          document.body.appendChild(link);
  
          link.click();
          link.remove();
  
          return true
        }
      }
    },
    getters: {
      url(){
        return process.env.NODE_ENV == 'development' ? 'https://localhost:11131' : ''
      }
    }
  }