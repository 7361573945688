<template>
    <v-app id="inspire">
    <v-app-bar
          app
          clipped-right
          color="green"
          dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>ProAlert</v-toolbar-title>
        <v-spacer></v-spacer>
        

        <v-btn color="green darken-2" @click="editor = true">
          <v-icon class="mr-2">mdi-account</v-icon>
          <span class="mr-2">Пользователь</span>
          <span>{{ user.name }}</span>
        </v-btn>
        
        <v-progress-linear
            absolute
            bottom
            color="green darken-3"
        />
      </v-app-bar>

      <v-navigation-drawer app v-model="drawer">
        <!-- Панель навигации -->
        <div class="pa-2">
          <v-img src="../../assets/carbis.png" width="80%"/>
        </div>
        <!-- <v-list>
          <v-list-item link to="/bot-list" exact>Список ботов</v-list-item>
          <v-list-item link to="/bot-addition" exact>Добавить бота</v-list-item>
          <v-list-item link @click="logout">
            <v-icon class="mr-4">mdi-door</v-icon>
            Выйти
          </v-list-item>
        </v-list> -->
      </v-navigation-drawer>

      <v-main style="position: relative;">
        <div class="d-flex flex-column fill-height">
          <div class="pa-2 flex-grow-1">
            <router-view  />
          </div>
        </div>
        
      </v-main>
    </v-app>
</template>


<script>
  import {mapGetters} from 'vuex'


  export default {
    name: 'User',
    data: () => ({
      editor: false,
      drawer: true
    }),
    components: {
    },
    methods: {
      async logout() {
        await this.$store.dispatch('logout')
      },
    },
    computed: {
      ...mapGetters(['user'])
    }
  }
</script>