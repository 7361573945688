<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>

<script>

import Login from './views/layouts/Login'
import MainPage from './views/layouts/MainPage'
import UserLoading from './views/layouts/UserLoading'

import {mapGetters} from 'vuex'

export default {
  name: 'App',
  data: () => ({
    user_loading : true
  }),
  components: {
    Login, MainPage, UserLoading
  },
  computed: {
    ...mapGetters(['token', 'user']),

    layout() {
      if (this.user_loading) {
        console.log('UserLoading')
        return 'UserLoading'
      } else if (this.token == undefined) {
        console.log('Login')
        return 'Login'
      } else {
        return 'MainPage'
      }
    }

  },
  async mounted() {
    try {
      await this.$store.dispatch('find_token')
    }
    catch(e) {
      console.error(e.message)
    }
    this.user_loading = false
  }
};
</script>
