import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import api from './modules/api'
import authorization from './modules/authorization'
import user from './modules/user'
import bots from './modules/bots'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    api,
    authorization,
    user,
    bots
  }
})
