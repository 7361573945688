export default {
  actions: {
    async login({dispatch}, { email, password }) {
      let response = await dispatch('api_request', {
        endpoint : '/login',
        body : {
          email, password
        }
      })
      console.log(response)
      await dispatch('set_token', response.token)
    },

    async logout({dispatch}) {
      await dispatch('set_token', undefined)
    },

    async get_new_password({dispatch}, email) {
      let response = await dispatch('api_request', {
        endpoint: '/new_password',
        body: {
          email
        }
      })
      return response
    }
  }
}